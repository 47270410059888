import React from 'react';
import styled from 'styled-components';
import Noise from '../../../images/product-assets/noise.png';
import { advantageCards } from './constant';

const Container = styled.section`
    color: #fff;
    background: linear-gradient(61.81deg, #4694c4 -39.36%, #6da7eb 83.09%);
    position: relative;
    overflow: hidden;
    z-index: -2;
`;

const ContentBox = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 54px 20px;
`;

const BgPattern = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
`;

const GridBox = styled.div`
    margin-top: 54px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 32px 44px;
    place-items: flex-start;

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
        gap: 32px;
        place-items: center center;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 32px;
        line-height: 37.92px;
    }

    @media screen and (max-width: 480px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column: span 2;

    @media screen and (max-width: 620px) {
        max-width: 300px;
    }

    &:last-child:nth-child(3n - 1) {
        @media screen and (min-width: 620px) {
            grid-column-end: -2;
        }
    }

    &:nth-last-child(2):nth-child(3n + 1) {
        @media screen and (min-width: 620px) {
            grid-column-end: 4;
        }
    }
`;

const Icons = styled.div`
    width: 48px;
    height: 48px;
    padding: 12px;
    background-color: #eaf7ff;
    border: 8px solid #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        width: 24px;
        height: 24px;
        filter: invert(17%) sepia(83%) saturate(1425%) hue-rotate(195deg) brightness(104%)
            contrast(92%);
    }
`;

const CardHeading = styled.h3`
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 20px;

    @media screen and (max-width: 800px) {
        font-size: 14px;
        line-height: 18px;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const CardDesc = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 12px;
        line-height: 18px;
    }

    @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

function Advantages() {
    return (
        <Container>
            <BgPattern src={Noise} alt="pattern" />
            <ContentBox>
                <Heading>Advantages</Heading>
                <GridBox>
                    {advantageCards?.map((card) => (
                        <Card key={card.id}>
                            <Icons>
                                <img src={card.icon} alt="Card Icon" />
                            </Icons>
                            <CardHeading>{card.title}</CardHeading>

                            <CardDesc>{card.desc}</CardDesc>
                        </Card>
                    ))}
                </GridBox>
            </ContentBox>
        </Container>
    );
}

export default Advantages;
