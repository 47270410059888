import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatText } from '../../../utils';
import ExpandIcon from '../../../images/product-assets/plus-circle.svg';
import CloseIcon from '../../../images/product-assets/minus-circle.svg';

const Details = styled.div`
    border-top: 1px solid #e8e8e8;
    padding: 24px 0 32px 0;

    @media only screen and (max-width: 1024px) {
        padding: 24px 0 12px 0;
    }

    @media screen and (max-width: 500px) {
        padding: 28px 0 25px 0;
    }

    & summary::-webkit-details-marker {
        display: none;
    }

    &:first-child {
        border: none;
        padding-top: 0;
        margin-top: 0;
    }
`;

const Summary = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const QueHeading = styled.h2`
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #262626;

    @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 18px;
        padding-right: 24px;
    }
`;

const Text = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #52525b;
    margin-top: 8px;
    overflow: hidden;
    height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight}px` : '0')};
    transition: height 0.3s ease-out;
`;

const Img = styled.img`
    width: 24px;
    height: 24px;
    padding: 0;
`;

const InfoLink = styled.a`
    color: #03045e;
    font-weight: 500;
`;

function Accordion({ question }) {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        // Update content height on mount and whenever isOpen changes
        if (contentRef?.current) {
            setContentHeight(contentRef?.current?.scrollHeight);
        }
    }, [isOpen, contentHeight]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Details onClick={toggleAccordion}>
            <Summary>
                <QueHeading>{formatText(question.title)}</QueHeading>
                <Img src={isOpen ? CloseIcon : ExpandIcon} alt="Accordion Icon" />
            </Summary>
            <Text isOpen={isOpen} contentHeight={contentHeight} ref={contentRef}>
                {question.desc}{' '}
                {question?.link && (
                    <InfoLink href={question.link} target="_blank" rel="noreferrer">
                        {question.linkText} ➚
                    </InfoLink>
                )}
            </Text>
        </Details>
    );
}

Accordion.propTypes = {
    question: PropTypes.object,
};

export default Accordion;
